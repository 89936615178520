import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isAuthenticated()) {
            let usr = this.authService.getCurrentUser();
            if(usr.hasAccess) {
                return true;
            }
            else {
                this.router.navigate(['/error/not-authorized']);
            }
            // user not authorized
            return false;
        }

        // user not authenticated
        return false;
    }
}
