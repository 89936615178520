import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { BlobService } from './services/blob.service';
import { BUStorageService } from './services/buStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Integrity_Elements';
  hasAccess : boolean;
  isAdmin = false;

  // Logged In User Name
  userFullName = ''
  // Business Units
  businessUnit = ''

  constructor(private authSvc: AuthService,
    private blobService: BlobService,
    private router: Router,
    private buStorageService: BUStorageService) {

    this.buStorageService.businessUnitUpdate$.subscribe(res => {
       if(res.length) {
        this.businessUnit = res[0].isShow ? res[0].bu : ''
       }
    })

  }

  ngOnInit(): void {
    let currentUser = this.authSvc.getCurrentUser();
    this.hasAccess = currentUser.hasAccess;
    this.userFullName = currentUser.email;
    this.isAdmin = currentUser.isAdmin;
  }

  back() {
    this.router.navigate(['/select-bu'])
  }

  addUser() {
    this.router.navigate(['/add-user'])
  }

}
