import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class BUStorageService {
    
    businessUnitUpdate$: Observable<any>;

    private businessUnitSubject = new BehaviorSubject([]);
    public businessUnitValue = '';
    
    constructor() {
        this.businessUnitUpdate$ = this.businessUnitSubject.asObservable();
    }

    updateBusinessUnitValue(value:any,isShow: boolean = true) {
        let buValues = []
        buValues.push({
          bu:  value,
          isShow: isShow
        })
        this.businessUnitSubject.next(buValues)
        this.businessUnitValue = value;
    }
    
}