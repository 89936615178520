<div class="d-flex flex-row flex-wrap mt-3">
  <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-lg-right text-center">
    <label for="driveSelection">Select Drive:</label>
  </div>


  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 text-lg-left text-center">
    <mat-form-field style="bottom: 20px;">
      <mat-select [(ngModel)]="selectedDrive">
          <mat-option *ngFor="let option of driveOptions;let i = index;" [value]="option">
              {{option}}
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-2">
    <small id="selectDriveHelp" class="form-text text-info">Info:Please select the drive that is currently not in use on your machine.The script will be generated based on the selected drive.</small>
  </div>

  <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-md-right text-center mb-2">
    <button type="submit" (click)="generate()" class="btn btn-sm btn-outline-primary">
      <i class="fa fa-link" aria-hidden="true"></i>
      Generate
    </button>
  </div>
</div>
