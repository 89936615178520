import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { lastValueFrom } from "rxjs";
import { BlobService } from "./blob.service";
import { LoaderService } from "./loader.service";

@Injectable()
export class AuthService {
    
    currentUserProfile : ICvxClaimsPrincipal;
    loggedIn = false;
    currentUser = [];

    constructor(private calAngularService: CalAngularService,
        private loaderService: LoaderService,
        private blobService: BlobService,
        private router: Router) { }

    async checkAccount() : Promise<boolean> {
        const isUserLoggedIn = await lastValueFrom(this.calAngularService.isUserSignedIn())
        this.loggedIn = isUserLoggedIn.valueOf()
        if(this.loggedIn) {
            this.currentUserProfile = this.calAngularService.cvxClaimsPrincipal;   
        }
         return isUserLoggedIn;
    }

    async getCALAccessToken() {
        const accessToken = await lastValueFrom(this.calAngularService.getAADToken())
        return accessToken
    }
    
    getToken(): string { 
        let token = JSON.parse(this.calAngularService.getAccessTokenFromCache()).secret
        return token
    }

    getAuthorizationHeader(): string {
        const token = this.getToken();
        return !!token
            ? `Bearer ${token}`
            : '';
    }

    getFullName(): string {
        let userInfo = this.calAngularService.getAccount()
        return userInfo
            ? userInfo.username
            : null;
    }
    
    isAuthorized(): boolean {
        return true;
    }

    isAuthenticated(): boolean {        
        return this.calAngularService.cvxClaimsPrincipal 
            ? this.calAngularService.cvxClaimsPrincipal.isUser
            : null; 
    }

    loadUserInfo(): Promise<any> {
        this.loaderService.show();
        return lastValueFrom(this.blobService.getClaims())
            .finally(() => this.loaderService.hide())
            .then((userInfo : any) => {
                this.currentUser = userInfo;
                return userInfo;
            })        
    }

    loadAPIUrl(): Promise<any> {
        this.loaderService.show()
        let name = this.getFullName()
        return lastValueFrom(this.blobService.getBUSelectionInfo(name))
            .finally(() => this.loaderService.hide())
            .then((buDetails : any) => {
                return buDetails
            })
            .catch((err) => {
                console.log(err)
                this.loaderService.hide()
                return []
            })
    }

    getCurrentUser(): any {
        return this.currentUser;
    }

}