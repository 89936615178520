
<div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5 flex-wrap">
        <h5>Not found</h5>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5 flex-wrap">
        <h6>
            Please correct page address or contact support if you believe this url is correct.
        </h6>
    </div>
</div>