import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CalAngularService } from '@cvx/cal-angular';
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor { 
    
    constructor(public authSvc: AuthService,private calAngularService: CalAngularService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthHeader(request);

        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    return this.calAngularService.getAADToken().pipe(
                        switchMap(() => {
                            request = this.addAuthHeader(request);
                            return next.handle(request);
                        }))
                }
                return throwError(() => error)
            }));
    }

    private addAuthHeader(request) {
        const authHeader = this.authSvc.getAuthorizationHeader();
        if (authHeader) {
            return request.clone({
                setHeaders: {
                    'Authorization': authHeader
                }
            });
        }
        return request;
    }

}