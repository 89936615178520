<div class="modal-header text-center">
  <h4 class="modal-title w-100">Delete Confirmation</h4>
  <button type="button" class="close pull-right" aria-label="Close"
      (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="d-flex flex-row flex-wrap">
    <div *ngIf="message" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        {{message}}
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="d-flex flex-row flex-wrap">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-md-right text-center">
      <button type="button" class="btn btn-sm btn-danger"(click)="modalRef?.hide()"> <i class="fa fa-times-circle" aria-hidden="true"></i> No</button>
      <button type="button" class="btn btn-sm btn-success active ml-3" (click)="confirm()"><i class="fa fa-check-circle" aria-hidden="true"></i> Yes</button>
    </div>
  </div>
</div>
