<div class="d-flex flex-row flex-wrap" style="margin-top: 8rem;">

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-right text-center">
        <h6>Business units:</h6>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-left text-center">
        <mat-form-field style="bottom: 20px;">
            <mat-select [(ngModel)]="selectedBU">
                <mat-option *ngFor="let unit of businessUnits;let i = index;" [value]="unit">
                    {{unit}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
        <button [disabled]="!selectedBU" (click)="goToNext()"
            type="button" class="btn btn-md btn-outline-primary">
            Next
            <i class="cursor_icon fa fa-arrow-circle-right" aria-hidden="true"></i>
        </button>
    </div>

</div>