import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-fileshare-script-generate',
  templateUrl: './fileshare-script-generate.component.html',
  styleUrls: ['./fileshare-script-generate.component.scss']
})

export class FileshareScriptGenerateComponent {
  driveOptions : string[] = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  selectedDrive = 'L';
  @Output() scriptGenerateEmitter = new EventEmitter<any>();

  generate() {
    this.scriptGenerateEmitter.emit(this.selectedDrive);
  }
}
