<form [formGroup]='folderForm' class="d-flex flex-row flex-wrap">
  
  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <label for="folderName">Folder name</label>
    <input type="text" formControlName="name" class="form-control" placeholder="Enter folder name">
    <small id="createFolderHelp" class="form-text text-danger">Folder will get saved only after uploading the files.</small>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-md-right text-center">
    <button type="submit" [disabled]="folderForm?.invalid" (click)="submitForm()" class="btn btn-sm btn-outline-primary">
      <i class="fa fa-floppy-o" aria-hidden="true"></i>
      Save
    </button>
  </div>

</form>
