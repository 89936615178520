import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUpload } from 'src/app/models/fileUpload';
import { AuthService } from 'src/app/services/auth.service';
import { BlobService } from 'src/app/services/blob.service';
import { BUStorageService } from 'src/app/services/buStorage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chunk-file-upload',
  templateUrl: './chunk-file-upload.component.html',
  styleUrls: ['./chunk-file-upload.component.scss']
})
export class ChunkFileUploadComponent {

  color = 'primary';
  mode = 'indeterminate';
  diameter='17.5';

  homeParams : any;

  selectedFiles:any [] = [];

  uploadedFilesLength = 0;
  totalFileSize = 0;

  chunkSize = environment.chunkConfig.chunkSize;
  chunkMinimumUploadSize = environment.chunkConfig.chunkMinimumUploadSize;

  hideFileRelatedOptions : boolean = false;
  uploadedAllFiles : boolean = false;

  @Output() fileUploadEmitter = new EventEmitter<any>();

  @Input() set HomeParams(params:any) {
    this.homeParams = params;
  }

  constructor(public blobService : BlobService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private buStorageService: BUStorageService) { }

  selectFiles(event): void {
    for(let file of event.target.files) {
      let findDuplicate = this.selectedFiles.filter(x => x.name == file.name)
      if(findDuplicate.length == 0) {
        this.totalFileSize += file.size;
        this.selectedFiles.push({
          'file': file,
          'progress':0,
          'name':file.name,
          'isSuccess':false,
          'isError':false,
          'isReady':false,
          'isUploading':false,
          'size':file.size,
          'isUploaded':false,
          'totalUploadedSize':0,
          'errorMessage':'',
          'contentType':file.type
        });
      }
    }
  }

  saveFileDetails(event) {
    event.target.disabled = true
    let fileDetails = []
    this.selectedFiles.forEach((x => {
      fileDetails.push({
        name : x.name,
        size : this.blobService.formatBytes(x.size),
        contentType: x.contentType
      })
    }))
    if(fileDetails.length) {

      // loading File Details FormData
      let fileUploadDetails = new FileUpload()
      fileUploadDetails.bu = this.buStorageService.businessUnitValue
      fileUploadDetails.path = this.homeParams.currentPath + this.homeParams.newFolderName
      fileUploadDetails.containerName = this.homeParams.containerName
      fileUploadDetails.emailID = this.authService.getFullName()
      if(["ABU","GOM"].includes(this.buStorageService.businessUnitValue)) {
        fileUploadDetails.uploadedDate = this.blobService.getDateAndTime()
      } // Setting Particularly for ABU,GOM
      let UploadDataModel = {
        exceldata : fileUploadDetails,
        files : fileDetails
      }

      this.loaderService.show()

      this.blobService.saveFileDetails(UploadDataModel).subscribe({
        next : (res) => {
            this.loaderService.hide()
            this.hideFileRelatedOptions = true;
            this.uploadFiles()
        },
        error : (err) => {
          this.loaderService.hide()
          console.log(err)
        }
      })
    }
  }

  async isBlobExists(fileIndex) {
    let formData = {
      BUName: this.buStorageService.businessUnitValue,
      FileName:this.selectedFiles[fileIndex].name
    };

    const query = Object.keys(formData).map(function (key) {
      return key + '=' + formData[key]
    }).join('&')

    // loading File FormData
    const fileFormData = new FormData()

    // loading File Details FormData
    fileFormData.append('bu', this.buStorageService.businessUnitValue)
    fileFormData.append('path', this.homeParams.currentPath + this.homeParams.newFolderName)
    fileFormData.append('containerName', this.homeParams.containerName)
    fileFormData.append('UploadedBy', this.authService.getFullName())
    fileFormData.append('contentType', this.selectedFiles[fileIndex].contentType)

    this.loaderService.show()
    return new Promise((resolve) => {
      this.blobService.checkBlobExists(fileFormData,query).subscribe({
        next : (res:any) => {
          this.loaderService.hide()
          if(res === true) {
            this.selectedFiles[fileIndex].errorMessage = 'File Already Exists'
            this.selectedFiles[fileIndex].isUploading = false;
            this.selectedFiles[fileIndex].isError = true;
            this.selectedFiles[fileIndex].isReady = false;
            resolve(false);
          }
          else {
            resolve(true);
          }
        },
        error : (err) => {
          this.loaderService.hide()
          console.log(err)
          resolve(false);
        }
      })
    })
  }

  async uploadFiles() {
    for (let fileIndex = 0; fileIndex < this.selectedFiles.length; fileIndex++) {
      if(this.selectedFiles[fileIndex].file.size >= this.chunkMinimumUploadSize) {
        // Chunk Upload
        const isBlobExist = await this.isBlobExists(fileIndex)
        if(isBlobExist) {
          await this.loadChunks(fileIndex, this.selectedFiles[fileIndex].file);
        }
      }
      else {
        // Normal Upload
        const isBlobExist = await this.isBlobExists(fileIndex)
        if(isBlobExist) {
          await this.uploadNormal(fileIndex, this.selectedFiles[fileIndex].file);
        }
      }
      if((fileIndex === this.selectedFiles.length -1) && this.uploadedFilesLength > 0)
      {
        this.fileUploadEmitter.emit();
      }
      this.uploadedAllFiles = true;
    }
  }

  setResponse(event: any, fileIndex : number) {
    if(event.body.statusCode == 200) {
      this.selectedFiles[fileIndex].isUploading = false;
      this.selectedFiles[fileIndex].isSuccess = true;
      this.selectedFiles[fileIndex].isUploaded = true;
      this.selectedFiles[fileIndex].isReady = false;
      this.uploadedFilesLength += 1;
    }
    else if(event.body.statusCode == 400) {
      this.selectedFiles[fileIndex].errorMessage = event.body.reasonPhrase
      this.selectedFiles[fileIndex].isUploading = false;
      this.selectedFiles[fileIndex].isError = true;
      this.selectedFiles[fileIndex].isReady = false;
    }
  }

  async uploadNormal(fileIndex:number,file:any) {
    if(!this.selectedFiles[fileIndex].isUploaded && !this.selectedFiles[fileIndex].isError) {

      this.selectedFiles[fileIndex].isUploading = true;

      // loading File FormData
      const fileFormData = new FormData()
      fileFormData.append('file', file)

      // loading File Details FormData
      fileFormData.append('bu', this.buStorageService.businessUnitValue)
      fileFormData.append('path', this.homeParams.currentPath + this.homeParams.newFolderName)
      fileFormData.append('containerName', this.homeParams.containerName)
      fileFormData.append('UploadedBy', this.authService.getFullName())
      fileFormData.append('FolderName', '')

      let formData = {
        BUName: this.buStorageService.businessUnitValue
      };

      const query = Object.keys(formData).map(function (key) {
        return key + '=' + formData[key]
      }).join('&')

      return new Promise((resolve) => {
        this.blobService.uploadNormal(fileFormData,query).subscribe({
          next : (event) => {
            if (event.type == HttpEventType.UploadProgress)
            {
              this.selectedFiles[fileIndex].progress = Math.round(100 * event.loaded / event.total);
              if(this.selectedFiles[fileIndex].progress == 100) {
                this.selectedFiles[fileIndex].isReady = true;
              }
            }
            if (event.type == HttpEventType.Response) {
              this.setResponse(event, fileIndex)
              resolve(this.uploadedFilesLength)
            }
          },
          error : (err) => {
            console.log(err)
            this.selectedFiles[fileIndex].errorMessage = err.error
            this.selectedFiles[fileIndex].isUploading = false;
            this.selectedFiles[fileIndex].isError = true;
            this.selectedFiles[fileIndex].isReady = false;
            resolve(this.uploadedFilesLength)
          }
        })
      })

    }
  }


  async uploadChunk(query, fileFormData, fileIndex,fileDetails,chunkIndex) {
    let eventTotalSize = 0
    return new Promise((resolve) => {
      this.blobService.uploadChunk(query,fileFormData).subscribe({
        next : (event) => {
          if(event.type == HttpEventType.UploadProgress)
          {
            eventTotalSize = event.total
            this.selectedFiles[fileIndex].progress = Math.round(100 * (this.selectedFiles[fileIndex].totalUploadedSize + event.loaded) /fileDetails.fileSize);
            if(this.selectedFiles[fileIndex].progress == 100) {
              this.selectedFiles[fileIndex].isReady = true;
            }
          }
          if (event.type == HttpEventType.Response) {
            this.selectedFiles[fileIndex].totalUploadedSize+= eventTotalSize;
            if(event.body.statusCode == 200) {
              if(chunkIndex === fileDetails.chunks - 1) {
                this.selectedFiles[fileIndex].isUploading = false;
                this.selectedFiles[fileIndex].isSuccess = true;
                this.selectedFiles[fileIndex].isUploaded = true;
                this.selectedFiles[fileIndex].isReady = false;
                this.uploadedFilesLength += 1;
              }
              resolve(this.uploadedFilesLength)
            }
            else if(event.body.statusCode == 400) {
              this.selectedFiles[fileIndex].errorMessage = event.body.reasonPhrase
              this.selectedFiles[fileIndex].isUploading = false;
              this.selectedFiles[fileIndex].isError = true;
              this.selectedFiles[fileIndex].isReady = false;
              resolve(this.uploadedFilesLength)
            }
          }
        },
        error : (err) => {
          console.log(err)
          this.selectedFiles[fileIndex].errorMessage = err.error
          this.selectedFiles[fileIndex].isUploading = false;
          this.selectedFiles[fileIndex].isError = true;
          this.selectedFiles[fileIndex].isReady = false;
          resolve(this.uploadedFilesLength)
        }
      })
    })
  }

  async loadChunks(fileIndex: number, file: any) {
    if(!this.selectedFiles[fileIndex].isUploaded) {
      this.selectedFiles[fileIndex].isUploading = true;
      if(file) {
        const chunks = Math.ceil(file.size / this.chunkSize);
        const fileDetails = { fileSize : file.size, chunks : chunks }

        for (let chunkIndex = 0; chunkIndex < chunks; chunkIndex++) {
          if(!this.selectedFiles[fileIndex].isError)
          {
            // loading File FormData
            const fileFormData = new FormData()
            const start = chunkIndex * this.chunkSize;
            const end = Math.min(start + this.chunkSize, file.size);
            fileFormData.append('file', file.slice(start,end))

            // loading FileDetails FormData
            fileFormData.append('bu', this.buStorageService.businessUnitValue)
            fileFormData.append('path', this.homeParams.currentPath + this.homeParams.newFolderName)
            fileFormData.append('containerName', this.homeParams.containerName)
            fileFormData.append('UploadedBy', this.authService.getFullName())
            fileFormData.append('contentType', this.selectedFiles[fileIndex].contentType)
            fileFormData.append('FolderName', '')

            // loading Query FormData
            let formData = {
              filename: this.selectedFiles[fileIndex].name,
              chunkIndex: chunkIndex,
              chunkFilesLength: chunks,
              BUName: this.buStorageService.businessUnitValue,
              totalFileSize: fileDetails.fileSize
            };

            const query = Object.keys(formData).map(function (key) {
              return key + '=' + formData[key]
            }).join('&')

            // calling API
            await this.uploadChunk(query,fileFormData,fileIndex,fileDetails,chunkIndex)
          }
          else {
            break;
          }
        }
      }
    }
  }

  remove(index,filesize) {
    this.totalFileSize -= filesize;
    this.selectedFiles.splice(index,1);
  }

  downloadFileDetails() {
    this.loaderService.show()
    let formData = {
      Email: this.authService.getFullName(),
      BUName: this.buStorageService.businessUnitValue
    };

    const query = Object.keys(formData).map(function (key) {
      return key + '=' + formData[key]
    }).join('&')

    this.blobService.downloadRecentHistory(query).subscribe({
      next:(res:any) => {
        this.loaderService.hide()
        const nameGenerator = this.blobService.excelNameGenerator()
        this.blobService.generateFile(res,nameGenerator)
      },
      error:(err:any) => {
        this.loaderService.hide()
      }
    })
  }

  ngOnDestroy() {
    this.selectedFiles = [];
    this.uploadedFilesLength = 0;
    this.totalFileSize = 0;
  }

}
