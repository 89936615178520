import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'folderNameSeperator'
})
export class FolderNameSeperatorPipe implements PipeTransform {

  transform(value: string,blob:any): string {
    let val = value.split('/')
    if(blob) {
      return val[val.length - 1]
    }
    else  {
      return val[val.length - 2]
    }
  }

}
