import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BlobService } from 'src/app/services/blob.service';
import { BUStorageService } from 'src/app/services/buStorage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileShareService } from 'src/app/services/fileshare.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // Popup Modal
  modalRef: BsModalRef;
  createFolderModalRef: BsModalRef;
  notifyOwnerModalRef: BsModalRef;
  uploadFolderModalRef: BsModalRef;
  generateScriptModalRef: BsModalRef;
  deleteContentModalRef: BsModalRef;
  // To Save Lists
  containers = [];
  topFolders = [];
  subFolders = [];
  // To Save Selected Item
  selectedContainer = '';
  selectedTopFolder = '';
  selectedSubFolder = '';
  // Current File Path
  currentPath = '/';
  // Logged In User Name
  userFullName = ''
  // Folder Hierarchy control
  showReverseIcon = false;
  // New Folder Name
  newFolderName = ''
  // Notification Control
  NotificationMessage = false;
  successNotifyMessage = '';
  errorNotifyMessage = '';
  // download spinner
  color = 'primary';
  mode = 'determinate';
  diameter='17.5';
  // Chevron User
  userDetails: any;
  // loading Scenario
  isTopFolderLoading: boolean = false;
  isSubFolderLoading: boolean = false;
  // Data to delete content
  selectedContent = ''
  constructor(public bsModalService: BsModalService,
              public blobService: BlobService,
              private authService: AuthService,
              private loaderService: LoaderService,
              private router: Router,
              public buStorageService: BUStorageService,
              private fileShareService: FileShareService,
              private toastrService: ToastrService) {
                if(!this.buStorageService.businessUnitValue) {
                  this.router.navigate(['/select-bu'])
                }
                else {
                  this.buStorageService.updateBusinessUnitValue(this.buStorageService.businessUnitValue)
                }
               }

  ngOnInit(): void {
    this.getAllContainers()
    this.userFullName = this.authService.getFullName()
    this.userDetails = this.authService.getCurrentUser()
  }

  getAllContainers() {
    this.loaderService.show()
    this.blobService.getContainers().subscribe({
      next : (res) => {
        this.loaderService.hide()
        if(res) {
          this.containers = res;
        }
      },
      error : (err) => {
        this.loaderService.hide()
      }
    })
  }

  getTopFolders() {
    this.loaderService.show()
    this.isTopFolderLoading = true;
    this.blobService.getTopFolders(this.selectedContainer).subscribe({
      next : (res) => {
        this.loaderService.hide()
        this.isTopFolderLoading = false;
        if(res) {
          this.topFolders = res;
        }
      },
      error : (err) => {
        this.loaderService.hide()
        this.isTopFolderLoading = false;
      }
    })
  }

  getSubFolders() {
    this.loaderService.show()
    this.isSubFolderLoading = true;
    this.subFolders = []
    this.blobService.getSubFolders(this.selectedContainer,this.selectedTopFolder).subscribe({
      next : (res) => {
        this.loaderService.hide()
        this.isSubFolderLoading = false;
        if(res) {
          this.subFolders = res;
        }
      },
      error : (err) => {
        this.loaderService.hide()
        this.isSubFolderLoading = false;
        console.log(err)
      }
    })
  }

  getSubTreeFolders() {
    this.loaderService.show()
    this.isSubFolderLoading = true;
    this.subFolders = []
    this.blobService.getSubFolders(this.selectedContainer,this.selectedSubFolder).subscribe({
      next : (res) => {
        this.loaderService.hide()
        this.isSubFolderLoading = false;
        if(res) {
          this.subFolders = res
        }
      },
      error : (err) => {
        this.loaderService.hide()
        this.isSubFolderLoading = false;
        console.log(err)
      }
    })
  }

  onSelectContainer(event:any) {
    if(event.value) {
      this.currentPath = '/'
      this.newFolderName = ''
      this.topFolders = []
      this.subFolders = []
      this.selectedTopFolder = '';
      this.selectedSubFolder = '';
      this.addContainerSuffixPath(event.value)
      this.getTopFolders()
      this.showBackIcon()
    }
  }

  onTopFolderSelection(value:any) {
    if(!value.isBlob) {
      this.subFolders = []
      if(this.selectedTopFolder) {
        if(this.selectedTopFolder != value.prefix) {
          this.currentPath = '/'
          this.selectedTopFolder = value.prefix
          this.addContainerSuffixPath(this.selectedContainer)
          this.addFolderSuffixPath(value.prefix)
        }
      }
      else {
        this.addFolderSuffixPath(value.prefix)
        this.selectedTopFolder = value.prefix
      }
      this.newFolderName = ''
      this.selectedSubFolder = '';
      this.getSubFolders();
      this.showBackIcon()
    }
  }

  onSubFolderSelection(value:any) {
    if(!value.isBlob) {
      if(this.selectedSubFolder) {
        if(this.selectedSubFolder != value.prefix) {
          this.currentPath = '/'
          this.selectedSubFolder = value.prefix
          this.addContainerSuffixPath(this.selectedContainer)
          this.addFolderSuffixPath(value.prefix)
        }
      }
      else {
        this.currentPath = '/'
        this.addContainerSuffixPath(this.selectedContainer)
        this.addFolderSuffixPath(value.prefix)
        this.selectedSubFolder = value.prefix
      }
      this.newFolderName = ''
      this.showBackIcon()
      this.getSubTreeFolders();
    }
  }

  addContainerSuffixPath(value) {
    this.currentPath += value+'/'
  }

  addFolderSuffixPath(value) {
    this.currentPath += value
  }

  showBackIcon() {
    if(this.selectedSubFolder.split('/').length > 2) {
      this.showReverseIcon = true
    }
    else {
      this.showReverseIcon = false
    }
  }

  previousSubFolder() {
    let currentSubFolderPath = this.selectedSubFolder
    currentSubFolderPath = currentSubFolderPath.slice(0, (currentSubFolderPath.slice(0, currentSubFolderPath.lastIndexOf('/'))).lastIndexOf('/')) + '/'
    this.currentPath = '/'
    this.addContainerSuffixPath(this.selectedContainer)
    this.addFolderSuffixPath(currentSubFolderPath)
    this.selectedSubFolder = currentSubFolderPath
    this.showBackIcon()
    this.getSubTreeFolders();
  }

  uploadFile(templateRef:TemplateRef<any>) {
    this.modalRef = this.bsModalService.show(
      templateRef, {class: 'modal-lg', backdrop:'static',keyboard:false}
      );
  }

  uploadFolder(templateRef:TemplateRef<any>) {
    this.uploadFolderModalRef = this.bsModalService.show(
      templateRef, {class: 'modal-lg', backdrop:'static',keyboard:false}
      );
  }

  createFolder(templateRef:TemplateRef<any>) {
    this.createFolderModalRef = this.bsModalService.show(
      templateRef, {class: 'modal-md', backdrop:'static',keyboard:false}
      );
  }

  notifyOwner(templateRef:TemplateRef<any>) {
    this.notifyOwnerModalRef = this.bsModalService.show(
      templateRef, {class: 'modal-md', backdrop:'static',keyboard:false}
      );

      this.bsModalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        this.NotificationMessage = false;
        this.successNotifyMessage = '';
        this.errorNotifyMessage = '';
      });
  }

  fileUploadRefresh() {
    // No new folder created , Only selected container
    if(this.selectedContainer && !this.selectedTopFolder && !this.selectedSubFolder && !this.newFolderName) {
      this.getTopFolders()
    }
    // New folder created , Only selected container
    else if(this.selectedContainer && !this.selectedTopFolder && !this.selectedSubFolder && this.newFolderName) {
      this.currentPath += this.newFolderName
      this.selectedTopFolder = this.newFolderName
      this.getTopFolders()
      this.getSubFolders()
    }
    // No new folder created , Only selected container, selected top folder
    else if(this.selectedContainer && this.selectedTopFolder && !this.selectedSubFolder && !this.newFolderName) {
      this.getTopFolders()
      this.getSubFolders()
    }
    // New folder created , Only selected container, selected top folder
    else if(this.selectedContainer && this.selectedTopFolder && !this.selectedSubFolder && this.newFolderName) {
      this.currentPath += this.newFolderName
      this.selectedSubFolder += this.selectedTopFolder + this.newFolderName
      this.getSubTreeFolders()
    }
    // No new folder created , Only selected container, selected top folder, selected sub folder
    else if(this.selectedContainer && this.selectedTopFolder && this.selectedSubFolder && !this.newFolderName) {
      this.getSubTreeFolders()
    }
    // New folder created , Only selected container, selected top folder, selected sub folder
    else if(this.selectedContainer && this.selectedTopFolder && this.selectedSubFolder && this.newFolderName) {
      this.currentPath += this.newFolderName
      this.selectedSubFolder += this.newFolderName
      this.getSubTreeFolders()
    }
    this.newFolderName = ''
    this.showBackIcon()
  }

  createFolderResult(value:any) {
    if(value) {
      this.createFolderModalRef.hide()
      this.newFolderName = value + '/';
    }
  }

  setProgress(index: number,type:string, progress : number) {
    if(type == 'topFolder') {
      this.topFolders[index]['progress'] = progress
    }
    else {
      this.subFolders[index]['progress'] = progress
    }
  }

  downloadFileShareDocument() {
    let filename = "FileShareDocs/Map_Network_Drive_Instructions.docx";
    this.loaderService.show();
    this.blobService.downloadFile("documentation",filename).subscribe({
      next: (res) => {
        this.loaderService.hide();
        if(res.type == HttpEventType.Response) {
          const element = document.createElement("a");
          element.href = URL.createObjectURL(res.body);
          element.download = filename.split('/').slice(-1).toString();
          element.click()
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  downloadFile(filename:string,index:number,type:string) {
    if(filename && this.selectedContainer) {
      // this.loaderService.show()
      this.setProgress(index, type, -1);
      this.blobService.downloadFile(this.selectedContainer,filename).subscribe({
        next : (res) => {
          // this.loaderService.hide()
          if(res.type == HttpEventType.DownloadProgress) {
            this.setProgress(index, type, Math.round(100 * res.loaded / res.total));
          }
          if(res.type == HttpEventType.Response) {
            const element = document.createElement("a");
            element.href = URL.createObjectURL(res.body);
            element.download = filename.split('/').slice(-1).toString();
            element.click()
            this.setProgress(index, type, -2);
          }
        },
        error : (err) => {
          // this.loaderService.hide()
          console.log(err)
          this.setProgress(index, type, -2);
        }
      })
    }
  }

  checkValidFolderName(parentFolderName) {
    const allowedChars = /^[a-zA-Z0-9_-]+$/;
    return allowedChars.test(parentFolderName);
  }

  submitNotifyOwner() {
    let folderPath = '/'+this.selectedContainer+'/'+ (this.selectedSubFolder ? this.selectedSubFolder : this.selectedTopFolder) + this.newFolderName;
    let parentFolderName = folderPath.split('/')[folderPath.split('/').length-2]

    if(this.checkValidFolderName(parentFolderName)) {
      let formData = {
        currentPath : folderPath,
        FromAddress : this.userFullName,
        BUName : this.buStorageService.businessUnitValue
      };

      const query = Object.keys(formData).map(function (key) {
        return key + '=' + formData[key]
      }).join('&')

      if(query) {
        this.loaderService.show()
        this.blobService.notifyOwner(query).subscribe({
          next : (res) => {
            this.loaderService.hide()
            this.NotificationMessage = true;
            this.successNotifyMessage = 'Notification Sent Successfully.'
          },
          error : (err) => {
            this.loaderService.hide()
            this.NotificationMessage = true;
            this.errorNotifyMessage = 'Error Sending Notification.'
          }
        })
      }
    }
    else {
      this.NotificationMessage = true;
      this.errorNotifyMessage = `Folder Name "${parentFolderName}" should not contains special characters except hyphen(-) and underscore(_).`
    }
  }

  downloadHistory() {
    this.loaderService.show()
    let formData = {
      Email: this.authService.getFullName(),
      BUName: this.buStorageService.businessUnitValue
    };

    const query = Object.keys(formData).map(function (key) {
      return key + '=' + formData[key]
    }).join('&')

    this.blobService.downloadRecentHistory(query).subscribe({
      next:(res:any) => {
        this.loaderService.hide()
        const nameGenerator = this.blobService.excelNameGenerator()
        this.blobService.generateFile(res,nameGenerator)
      },
      error:(err:any) => {
        this.loaderService.hide()
      }
    })
  }

  openFileSharePopup(templateRef:TemplateRef<any>) {
    this.generateScriptModalRef = this.bsModalService.show(templateRef, {class: 'modal-md', backdrop:'static',keyboard:false});
  }

  generateScript(value) {
    this.generateScriptModalRef.hide();
    this.loaderService.show();

    let fileShareScript = this.fileShareService.getFileShareScript();
    fileShareScript = fileShareScript.replace("[driveName]",value ? value: "L");

    if(this.userDetails.isChevronUser) {
      this.loaderService.hide()
      const blob = new Blob([fileShareScript], {type : 'text/plain'});
      const nameGenerator = this.blobService.textNameGenerator()
      this.blobService.generateFile(blob, nameGenerator);
    }

    else {
    this.fileShareService.getFileShareScriptKey().subscribe({
      next: (res) => {
        if(res.script != null) {
          this.loaderService.hide();
          fileShareScript = fileShareScript.replace("[passKey]", window.atob(res.script));
          const blob = new Blob([fileShareScript], {type : 'text/plain'});
          const nameGenerator = this.blobService.textNameGenerator()
          this.blobService.generateFile(blob, nameGenerator);
        }
      },
      error: (err) => {
        this.loaderService.hide()
        console.log(err)
      }
    })
    }
  }


  folderUploadRefresh() {
    this.fileUploadRefresh()
  }

  openDeleteContentsPopup(data:any,templateRef: TemplateRef<any>, event:any) {
    event.stopPropagation();
    this.selectedContent = data;
    this.deleteContentModalRef = this.bsModalService.show(templateRef,{class: 'modal-md', backdrop:'static',keyboard:false})
  }

  deleteFolder(value) {
    this.loaderService.show()

    let formData = {
      containerName : this.selectedContainer,
      BUName: this.buStorageService.businessUnitValue,
      folderPath: value.prefix
    };

    const query = Object.keys(formData).map(function (key) {
      return key + '=' + formData[key]
    }).join('&')

    this.fileShareService.deleteFolder(query).subscribe({
      next:(res:any) => {
        this.loaderService.hide()
        if(res.statusCode == 200) {
          this.toastrService.success("Deleted Successfully !!!")
          this.fileUploadRefresh()
        }
      },
      error:(err:any) => {
        console.log(err)
        this.toastrService.error("Failed to Delete.")
        this.loaderService.hide()
      }
    })
  }

  deleteFile(value) {
    this.loaderService.show()

    let formData = {
      containerName : this.selectedContainer,
      BUName: this.buStorageService.businessUnitValue,
      filePath: value.blob.name
    };

    const query = Object.keys(formData).map(function (key) {
      return key + '=' + formData[key]
    }).join('&')

    this.fileShareService.deleteFile(query).subscribe({
      next:(res:any) => {
        this.loaderService.hide()
        if(res.statusCode == 200) {
          this.toastrService.success("Deleted Successfully !!!")
          this.fileUploadRefresh()
        }
      },
      error:(err:any) => {
        console.log(err)
        this.loaderService.hide()
        this.toastrService.error("Failed to Delete.")
      }
    })
  }

  contentDeleteRefresh(value) {
    if(value.isBlob) {
      this.deleteFile(value);
    }
    else {
      this.deleteFolder(value);
    }
  }
}
