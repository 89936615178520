export class FileUpload {
    containerName : string;
    bu: string;
    path: string;
    name: string;
    size: string;
    contentType: string;
    emailID: string;
    uploadedDate:string;
}
