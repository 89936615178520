
<div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5 flex-wrap">
        <h5>Not authorized</h5>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5 flex-wrap">
        <h6>
            You do not have access to this page.
        </h6>
    </div>
</div>