<form [formGroup]="addUserForm">

  <div class="d-flex flex-column justify-content-center align-items-center flex-wrap" style="margin-top: 0.25rem;">

    <div class="col-lg-12">

      <div class="row">
        <div class="col-lg-4"></div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center mb-1">
          <strong> Manage User </strong>
        </div>

      </div>

    </div>


    <div class="form-group col-lg-6">
      <div class="row">

        <div class="col-lg-2"></div>

        <div class="col-lg-8">
          <label class="form-label">Email: <span class="text-danger">*</span></label>
          <input type="text" class="form-control" formControlName="email" placeholder="Enter email address">
          <ng-container *ngIf="addUserForm.get('email').errors">
            <div class="text-danger form-field-error" *ngIf="addUserForm.get('email').touched">
              * Email is mandatory
            </div>
          </ng-container>
        </div>

        <div class="col-lg-1 text-lg-left text-center align-self-center">
          <a (click)="addUserForm?.get('email')?.value ? getUser() : $event.preventDefault()" title="Get user details"><i class="fa fa-refresh cursor_icon" aria-hidden="true" style="color: #0b2d71;"></i></a>
        </div>

        <div class="col-lg-1 text-lg-left text-center align-self-center">
          <a (click)="addUserForm?.get('email')?.value ? deleteUser() : $event.preventDefault()" title="Delete user"><i class="fa fa-trash cursor_icon" aria-hidden="true" style="color: #0b2d71;"></i></a>
        </div>

      </div>

    </div>

    <div class="form-group col-lg-4">
      <label class="form-label">Username: <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="userName" placeholder="Enter user name">
      <ng-container *ngIf="addUserForm.get('userName').errors">
        <div class="text-danger form-field-error" *ngIf="addUserForm.get('userName').touched">
          * Username is mandatory
        </div>
      </ng-container>
    </div>

    <div formArrayName="BUs" class="form-group col-lg-4">
      <label class="form-label">Business Unit(s): <span class="text-danger">*</span></label>
      <div class="col-lg-12">
        <div class="row">
          <ng-container *ngFor="let bu of addUserForm.get('BUs')?.controls;let i = index;">
            <div class="form-check col-lg-6">
              <input class="form-check-input" type="checkbox" [checked]="bu?.value?.isSelected" (change)="onBUSelection(bu, $event)">
              <label class="form-check-label">{{bu.value?.name}}</label>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

    <div class="form-group col-lg-4">
      <label class="mr-2 form-label">Role: <span class="text-danger">*</span></label>
      <div class="form-check-inline">
        <input type="radio" class="form-check-input" value="Chevron" formControlName="role">
        <label class="form-check-label">Chevron</label>
      </div>
      <div class="form-check-inline">
        <input type="radio" class="form-check-input" value="Guest" formControlName="role">
        <label class="form-check-label">Guest</label>
      </div>
      <ng-container *ngIf="addUserForm.get('role').errors">
        <div class="text-danger form-field-error" *ngIf="addUserForm.get('role').touched">
          * Role is mandatory
        </div>
      </ng-container>
    </div>

    <button type="button" (click)="addUser()"  class="btn btn-md btn-primary">
      Submit
    </button>

  </div>

</form>
