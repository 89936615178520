import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserDetails } from 'src/app/models/userDetails';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent {
 addUserForm: FormGroup;

 availableBUs = [];
 selectedBUs = [];

 constructor(private formBuilder: FormBuilder,private loaderService: LoaderService,private userService: UserService, private toastrService: ToastrService) {
    this.addUserForm = this.formBuilder.group({
      email: ['', Validators.required],
      userName: ['', Validators.required],
      role: ['', Validators.required],
      BUs : this.formBuilder.array([])
    })
 }

 ngOnInit() {
    this.getAvailableBUs();
 }

 getAvailableBUs() {
  this.loaderService.show();
  this.userService.getavailableBUs().subscribe({
    next:(res) => {
      this.loaderService.hide();
      this.availableBUs = res;
      this.availableBUs.forEach(value => {
        this.pushBusinessUnits(value);
      })
    },
    error: (err) => {
      this.loaderService.hide();
      console.log(err);
    }
  })
 }

 pushBusinessUnits(bu, selectedBU = '') {
  const formArray = this.addUserForm.get('BUs') as FormArray;
  const newBU = this.formBuilder.group({
      name: bu.name,
      isSelected : bu.name === selectedBU ? true : false
  })

  formArray.push(newBU);
 }

 validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
}

  validateBUs() {
   if(this.selectedBUs.length > 0) {
    return true;
   }
   else {
    this.toastrService.error("Please select atleast one business unit");
    return false;
   }
  }

 addUser() {
    if(this.validateBUs()) {
      if(this.addUserForm.valid) {
        let userDetails = new UserDetails();
        userDetails.BU = this.selectedBUs.toString();
        userDetails.EmailID = this.addUserForm.get('email').value;
        userDetails.Role = this.addUserForm.get('role').value;
        userDetails.Username = this.addUserForm.get('userName').value;
        this.loaderService.show();

        this.userService.addUser(userDetails).subscribe({
          next:(res) => {
            this.loaderService.hide();
            if(res.statusCode == 200) {
             this.toastrService.success("Saved Successfully")
            }
          },
          error: (err) => {
            this.loaderService.hide();
            this.toastrService.error(err)
            console.log(err);
          }
        })

      }
      else {
        this.validateAllFormFields(this.addUserForm);
      }
    }
 }

 clearFormArray(controlName) {
  const formArray = <FormArray>this.addUserForm.controls[controlName];
  while (formArray.length > 0 ) { formArray.removeAt(0) }
 }

 deleteUser() {
  this.loaderService.show()
  this.userService.getUser(this.addUserForm.get('email').value).subscribe({
    next:(res) => {
      if(res.emailID != null) {
        this.userService.deleteUser(this.addUserForm.get('email').value).subscribe({
          next:(result) => {
            this.loaderService.hide();
            if(result.statusCode == 200) {
             this.toastrService.success("Deleted Successfully")
            }
          },
          error: (err) => {
            this.loaderService.hide();
            console.log(err);
            this.toastrService.error(err)
          }
        })
      }
      else {
        this.loaderService.hide();
        this.toastrService.info("No user details found");
      }
    },
    error: (err) => {
      this.loaderService.hide();
      console.log(err);
      this.toastrService.error(err)
    }
  })
 }

 getUser() {
  this.loaderService.show()
  this.userService.getUser(this.addUserForm.get('email').value).subscribe({
    next:(res) => {
      this.loaderService.hide();
      if(res.emailID != null) {
        this.clearFormArray("BUs");
        this.selectedBUs = [];
        this.addUserForm.reset();
        this.addUserForm.patchValue({
          'email': res.emailID,
          'userName': res.username,
          'role': res.role
        })
        this.selectedBUs = res.bu.split(',');

        this.availableBUs.forEach(bu => {
          let selectedBU = this.selectedBUs.find(x => x == bu.name);
          this.pushBusinessUnits(bu, selectedBU);
        })
      }
      else {
        this.toastrService.info("No user details found");
      }
    },
    error: (err) => {
      this.loaderService.hide();
      console.log(err);
      this.toastrService.error(err)
    }
  })
 }

 onBUSelection(bu: FormControl, event: any) {
  if(event.target.checked) {
    bu.get('isSelected').setValue(true);
    this.selectedBUs.push(bu.value.name);
  }
  else {
    bu.get('isSelected').setValue(false);
    let index = this.selectedBUs.indexOf(bu.value.name);
    if(index > -1) {
      this.selectedBUs.splice(index,1);
    }
  }
 }

}
