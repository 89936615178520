import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BUStorageService } from './buStorage.service';

@Injectable({
  providedIn: 'root'
})

export class FileShareService {
  userDetails: any;

  fileShareScriptNonChevron = "\$connectTestResult\ = Test\-NetConnection \-ComputerName integrtestcvx\.file\.core\.windows\.net \-Port 445 \n if \(\$connectTestResult\.TcpTestSucceeded\) \{ \n \# Save the password so the drive will persist on reboot \n cmd.exe \/C \"cmdkey \/add\:\`\"integrtestcvx\.file\.core\.windows.net\`\" \/user\:\`\"localhost\\integrtestcvx\`\" \/pass\:[passKey] \n \# Mount the drive \n New\-PSDrive \-Name [driveName] \-PSProvider FileSystem \-Root \"\\\\integrtestcvx\.file\.core\.windows\.net\\fileshare\\raw\" \-Persist\} else \{Write\-Error \-Message \"Unable to reach the Azure storage account via port 445\. Check to make sure your organization or ISP is not blocking port 445\, or use Azure P2S VPN\, Azure S2S VPN\, or Express Route to tunnel SMB traffic over a different port\.\"\}";
  fileShareScriptChevron = "\$connectTestResult\ = Test\-NetConnection \-ComputerName integrtestcvx\.file\.core\.windows\.net \-Port 445 \n if \(\$connectTestResult\.TcpTestSucceeded\) \{\n \# Mount the drive \n New\-PSDrive \-Name [driveName] \-PSProvider FileSystem \-Root \"\\\\integrtestcvx\.file\.core\.windows\.net\\fileshare\" \-Persist \n \} else \{ \n Write\-Error \-Message \"Unable to reach the Azure storage account via port 445\. Check to make sure your organization or ISP is not blocking port 445\, or use Azure P2S VPN\, Azure S2S VPN\, or Express Route to tunnel SMB traffic over a different port\.\"\}";

  GOMFileShareScriptNonChevron = "\$connectTestResult\ = Test\-NetConnection \-ComputerName integrgomtestcvx\.file\.core\.windows\.net \-Port 445 \n if \(\$connectTestResult\.TcpTestSucceeded\) \{ \n \# Save the password so the drive will persist on reboot \n cmd.exe \/C \"cmdkey \/add\:\`\"integrgomtestcvx\.file\.core\.windows.net\`\" \/user\:\`\"localhost\\integrgomtestcvx\`\" \/pass\:[passKey] \n \# Mount the drive \n New\-PSDrive \-Name [driveName] \-PSProvider FileSystem \-Root \"\\\\integrgomtestcvx\.file\.core\.windows\.net\\fileshare\\raw\" \-Persist\} else \{Write\-Error \-Message \"Unable to reach the Azure storage account via port 445\. Check to make sure your organization or ISP is not blocking port 445\, or use Azure P2S VPN\, Azure S2S VPN\, or Express Route to tunnel SMB traffic over a different port\.\"\}";
  GOMFileShareScriptChevron = "\$connectTestResult\ = Test\-NetConnection \-ComputerName integrgomtestcvx\.file\.core\.windows\.net \-Port 445 \n if \(\$connectTestResult\.TcpTestSucceeded\) \{\n \# Mount the drive \n New\-PSDrive \-Name [driveName] \-PSProvider FileSystem \-Root \"\\\\integrgomtestcvx\.file\.core\.windows\.net\\fileshare\" \-Persist \n \} else \{ \n Write\-Error \-Message \"Unable to reach the Azure storage account via port 445\. Check to make sure your organization or ISP is not blocking port 445\, or use Azure P2S VPN\, Azure S2S VPN\, or Express Route to tunnel SMB traffic over a different port\.\"\}";

  constructor(private http: HttpClient,private authService: AuthService,private buStorageService: BUStorageService) {
    this.userDetails = this.authService.getCurrentUser();
   }

  getFileShareScript() : string {
    let script = "";
    switch(this.buStorageService.businessUnitValue) {
      case "ABU" :
        script = this.userDetails.isChevronUser ? this.fileShareScriptChevron : this.fileShareScriptNonChevron;
        break;
      case "GOM":
        script = this.userDetails.isChevronUser ? this.GOMFileShareScriptChevron : this.GOMFileShareScriptNonChevron;
        break;
    }
    return script;
  }

  getFileShareScriptKey() : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BUName",this.buStorageService.businessUnitValue);
    return this.http.get(`${environment.apiEndPoint}/Home/getFileShareScriptKey`,{params: queryParams});
  }

  deleteFile(params) : Observable<any> {
    return this.http.post(`${environment.apiEndPoint}/Home/delete/fileshare/file`+ (params ? '?' + params : ''),'');
  }

  deleteFolder(params) : Observable<any> {
    return this.http.post(`${environment.apiEndPoint}/Home/delete/fileshare/folder`+ (params ? '?' + params : ''),'');
  }

}
