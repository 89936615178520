<div class="row">

    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">

        <button *ngIf="!hideFileRelatedOptions" type="button" class="btn btn-sm btn-choose-file"
            (click)="folderUploaderButton?.click()">
            <i class="fa fa-folder" aria-hidden="true"></i>
            Choose folder
        </button>        

        <span class="hide-input-button">
        <input 
            #folderUploaderButton
            type="file" 
            name="blobFileUpload"             
            webkitDirectory
            directory multiple
            (change)="selectFolder(folderUploaderButton?.files)" />
        </span>                       
    </div>

    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
        <small class="text-muted">
            Upload path : {{'/'+homeParams?.containerName+'/'+homeParams?.currentPath+homeParams?.newFolderName}}            
        </small>
    </div>

    <div *ngIf="uploadedFolders.length > 0" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">

            <div class="card-header text-center" style="padding: 2px;">
                Uploaded folder
            </div>
            
            <div class="card-body mat-card-content">
                <ul class="list-group list-group-flush" style="max-height: 75px;">
                    <li 
                        *ngFor="let folder of uploadedFolders;let i = index;" 
                        style="padding: 2px;" 
                        class="list-group-item">
                        <div class="row">

                            <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" style="white-space: nowrap;"
                                 title="{{folder?.name}}">
                                {{folder?.name?.length > 40 ? folder?.name.slice(0,40) + '...'  : folder?.name}} 
                                <small class="text-muted font-weight-bold">({{blobService?.formatBytes(totalFileSize)}})</small>
                                <i *ngIf="!folder?.isReady" 
                                    (click)="remove()" 
                                    class="cursor_icon trash fa fa-trash ml-1" aria-hidden="true" title="Remove"></i>
                            </div>        

                            <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">

                                <div *ngIf="folder?.isUploading" class="progress position-relative" style="margin-bottom: 0;">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': folder?.progress + '%' }">
                                        <large class="justify-content-center d-flex position-absolute w-100" style="color: #121111;font-weight: bolder;">
                                            {{folder?.progress+'%'}}
                                        </large>
                                    </div>
                                </div>                             

                                <ng-container *ngIf="folder?.isSuccess">
                                    <i class="cursor_icon success fa fa-check-circle" aria-hidden="true" title="{{folder?.info}}"></i>
                                </ng-container>

                                <ng-container *ngIf="folder?.isPartialSuccess">
                                    <i class="cursor_icon success fa fa-check-circle" style="color: darkgoldenrod;" aria-hidden="true" title="{{folder?.info}}"></i>
                                </ng-container>
                                
                                <ng-container *ngIf="folder?.isError">
                                    <i class="cursor_icon error fa fa-times-circle" aria-hidden="true" title="{{folder?.info}}"></i>
                                </ng-container>

                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="card-footer" style="padding: 2px;">
                <div class="row">

                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-left text-center">
                        <small class="ml-lg-3 font-weight-bold">Total size : {{blobService?.formatBytes(totalFileSize)}}</small>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-right text-center">
                        <small class="mr-lg-3 font-weight-bold">Result : {{uploadedFilesLength}} out of {{selectedFiles?.length}} file(s) uploaded.</small><br>
                    </div>

                </div>
            </div>
            
        </div>   
    </div>

    <div *ngIf="selectedFiles?.length > 0" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-lg-right text-center">

        <button *ngIf="hideFileRelatedOptions" type="button" class="btn btn-sm btn-outline-primary mt-1"
                (click)="downloadFileDetails()" [disabled]="!uploadedAllFiles">
                <i class="fa fa-download" aria-hidden="true"></i>
            Download Result
        </button>

        <button *ngIf="!hideFileRelatedOptions" type="button" class="btn btn-sm btn-outline-primary mt-1"
                (click)="saveFileDetails($event)" [disabled]="!selectedFiles">
                <i class="fa fa-upload" aria-hidden="true"></i>
            Upload
        </button>

    </div>

</div>