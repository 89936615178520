<br />

<div class="row main-wrapper">

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">

        <div class="row" style="margin-bottom: -35px;">

            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-right text-center">
                <h6>Select container:</h6>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-left text-center">
                <mat-form-field style="bottom: 20px;">
                    <mat-select [(ngModel)]="selectedContainer"
                        (selectionChange)="onSelectContainer($event)">
                        <mat-option *ngFor="let container of containers;let i = index;" [value]="container">
                            {{container}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <div class="row" style="margin-bottom: -45px;">

            <div *ngIf="selectedContainer" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="back_icon_wrapper">
                    <i class="fa fa-arrow-down navigation_icon" aria-hidden="true"></i>
                </div>
            </div>

        </div>

        <div *ngIf="selectedContainer" class="row" style="margin-bottom: -20px;">

            <div *ngIf="!isTopFolderLoading && topFolders?.length > 0" class="p-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-3">
                    <div class="card-header text-center" style="padding: 2px;">
                        Top folder
                    </div>
                    <div class="card-body mat-card-content">
                        <ul class="list-group list-group-flush" style="max-height: 75px;">
                            <li *ngFor="let folder of topFolders;let folderIndex=index;"
                            class="list-group-item folder"
                            (click)="activeFolder=folder;onTopFolderSelection(folder)"
                            [class.activeFolder]="folder==activeFolder" style="padding: 2px;">

                                <div class="row">

                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-truncate">

                                        <ng-container *ngIf="!folder?.isBlob">
                                            <span class="cursor_icon" title="{{folder?.prefix}}">
                                                {{folder?.prefix}}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="folder?.isBlob">
                                            <span title="{{folder?.blob?.name}}">
                                                {{folder?.blob?.name}}
                                            </span>
                                        </ng-container>

                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-center text-center" *ngIf="folder?.isBlob">

                                        <ng-container>
                                            <i
                                                *ngIf="folder?.progress == undefined || folder?.progress == -2"
                                                title="{{'File Size : ' + blobService?.formatBytes(folder?.blob?.properties?.contentLength)}}"
                                                (click)="downloadFile(folder?.blob?.name,folderIndex,'topFolder')"
                                                class="view-file fa fa-download cursor_icon"
                                                aria-hidden="true">
                                            </i>
                                        </ng-container>

                                        <ng-container>
                                            <span class="view-progress" *ngIf="folder?.progress > -1">
                                                {{folder?.progress +'%'}}
                                            </span>
                                            <div style="position: absolute; left: 5px; transform: translateX(100%);">
                                            <mat-progress-spinner
                                                *ngIf="folder?.progress == -1"
                                                class="download_spinner"
                                                mode='indeterminate'
                                                [color]="primary"
                                                [diameter]="diameter"
                                                [value]="50"
                                                >
                                            </mat-progress-spinner>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="(buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM') && userDetails?.isAdmin">
                                          <i
                                              (click)="openDeleteContentsPopup(folder,deleteContentTemplate,$event)"
                                              class="view-file fa fa-trash cursor_icon"
                                              aria-hidden="true">
                                          </i>
                                        </ng-container>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center icon-center" *ngIf="!folder?.isBlob">
                                      <ng-container *ngIf="(buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM') && userDetails?.isAdmin">
                                        <i
                                            (click)="openDeleteContentsPopup(folder,deleteContentTemplate,$event)"
                                            class="view-file fa fa-trash cursor_icon"
                                            aria-hidden="true">
                                        </i>
                                      </ng-container>
                                  </div>

                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="!isTopFolderLoading && topFolders?.length === 0" class="p-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="card mb-3">
                <div class="card-header text-center" style="padding: 2px;">
                    Top folder
                </div>
                <div class="card-body mat-card-content">
                  <div class="row">
                    <div class="col-12 text-center">
                      No Data Found
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>

        <div class="row" style="margin-bottom: -45px;">

            <div *ngIf="selectedTopFolder" style="z-index: 1;" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="back_icon_wrapper">
                    <i class="fa fa-arrow-down navigation_icon" aria-hidden="true"></i>
                    <i *ngIf="showReverseIcon" (click)="previousSubFolder()" class="back_to_sub navigation_icon fa fa-chevron-circle-left cursor_icon"
                        aria-hidden="true"></i>
                </div>
            </div>

        </div>

        <div class="row" *ngIf="selectedTopFolder">

            <div *ngIf="!isSubFolderLoading && subFolders?.length > 0" class="p-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-3">
                    <div class="card-header text-center" style="padding: 2px;">
                        Sub folder
                    </div>
                    <div class="card-body mat-card-content">
                        <ul class="list-group list-group-flush" style="max-height: 75px;">
                            <li *ngFor="let folder of subFolders;let subfolderIndex=index;"
                                class="list-group-item folder"
                                (click)="activeSubFolder=folder;onSubFolderSelection(folder)"
                                [class.activeFolder]="folder==activeSubFolder"
                                style="padding: 2px;">

                                <div class="row">

                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-truncate">

                                        <ng-container *ngIf="!folder?.isBlob">
                                            <span class="cursor_icon" title="{{(folder?.prefix | folderNameSeperator : folder?.isBlob) + '/'}}">
                                                {{(folder?.prefix | folderNameSeperator : folder?.isBlob) + '/'}}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngIf="folder?.isBlob">
                                            <span  title="{{folder?.blob?.name | folderNameSeperator : folder?.isBlob}}">
                                                {{folder?.blob?.name | folderNameSeperator : folder?.isBlob }}
                                            </span>
                                        </ng-container>

                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center icon-center" *ngIf="folder?.isBlob">

                                        <ng-container>
                                            <i
                                                *ngIf="folder?.progress == undefined || folder?.progress == -2"
                                                title="{{'File Size : ' + blobService?.formatBytes(folder?.blob?.properties?.contentLength)}}"
                                                (click)="downloadFile(folder?.blob?.name,subfolderIndex,'subFolder')"
                                                class="view-file fa fa-download cursor_icon"
                                                aria-hidden="true">
                                            </i>
                                        </ng-container>

                                        <ng-container>
                                            <span class="view-progress" *ngIf="folder?.progress > -1">
                                                {{folder?.progress +'%'}}
                                            </span>
                                            <div style="position: absolute; left: 5px; transform: translateX(100%);">
                                              <mat-progress-spinner
                                              *ngIf="folder?.progress == -1"
                                              class="download_spinner"
                                              mode='indeterminate'
                                              [color]="primary"
                                              [diameter]="diameter"
                                              [value]="50"
                                              >
                                          </mat-progress-spinner>
                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="(buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM')  && userDetails?.isAdmin">
                                          <i
                                              (click)="openDeleteContentsPopup(folder,deleteContentTemplate,$event)"
                                              class="view-file fa fa-trash cursor_icon"
                                              aria-hidden="true">
                                          </i>
                                        </ng-container>

                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center icon-center" *ngIf="!folder?.isBlob">
                                        <ng-container *ngIf="(buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM')  && userDetails?.isAdmin">
                                          <i
                                              (click)="openDeleteContentsPopup(folder,deleteContentTemplate,$event)"
                                              class="view-file fa fa-trash cursor_icon"
                                              aria-hidden="true">
                                          </i>
                                        </ng-container>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="!isSubFolderLoading && subFolders?.length === 0" class="p-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="card mb-3">
                <div class="card-header text-center" style="padding: 2px;">
                    Sub folder
                </div>
                <div class="card-body mat-card-content">
                  <div class="row">
                    <div class="col-12 text-center">
                      No Data Found
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>

    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <strong> Instructions : </strong>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                <small>
                    <b> Upload file(s): </b> Click on Upload file(s) and select required file(s) to be uploaded.
                    <br>
                    <b> Upload folder: </b> Click on Upload folder and select required folder to be uploaded.
                    <br>
                    <b> Create folder: </b> Click on Create folder button. Enter folder name and select file(s)/folder to be
                        uploaded in creating folder. File(s)/Folder selection is mandatory.
                    <br>
                    <b> Notify BU Owner: </b> Click on Notify BU Owner to send an email to the respective owner about newly uploaded file(s).
                    <br>
                    <b> Recent Upload History : </b> Click on Recent Upload History to download the status of the recently uploaded file(s)/folder.
                </small>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <strong> Current path : </strong>
                <div>
                    <small>  {{currentPath}} <b>{{newFolderName}}</b> </small>
                </div>
            </div>
        </div>

        <div class="row mt-3">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <button type="button" [disabled]="!selectedContainer" class="btn btn-sm btn-outline-primary"
                    (click)="uploadFile(uploadTemplate)">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    Upload file(s)
                </button>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <button type="button" [disabled]="!selectedContainer" class="btn btn-sm btn-outline-primary"
                    (click)="uploadFolder(uploadFolderTemplate)">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    Upload folder
                </button>
            </div>

        </div>

        <div class="row mt-2">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <button type="button" [disabled]="!selectedContainer" class="btn btn-sm btn-outline-primary"
                    (click)="createFolder(folderTemplate)">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                    Create folder
                </button>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <button type="button" [disabled]="!selectedContainer" class="btn btn-sm btn-outline-primary"
                    (click)="notifyOwner(notifyOwnerTemplate)">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    Notify BU Owner
                </button>
            </div>

        </div>

        <div class="row mt-2" *ngIf="buStorageService.businessUnitValue == 'SASBU' || buStorageService.businessUnitValue == 'NMA'">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <button type="button" class="btn btn-sm btn-outline-primary"
                  (click)="downloadHistory()">
                  <i class="fa fa-history" aria-hidden="true"></i>
                  Recent Upload History
              </button>
          </div>

        </div>

        <div class="row mt-2" *ngIf="buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM'">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <button type="button" class="btn btn-sm btn-outline-primary"
                    (click)="downloadHistory()">
                    <i class="fa fa-history" aria-hidden="true"></i>
                    Recent Upload History
                </button>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
              <button type="button" class="btn btn-sm btn-outline-primary"
                  (click)="downloadFileShareDocument()">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  Fileshare Instructions
              </button>
          </div>

        </div>

        <div class="row mt-2" *ngIf="buStorageService.businessUnitValue === 'ABU' || buStorageService.businessUnitValue === 'GOM'">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
            <button type="button" class="btn btn-sm btn-outline-primary"
                (click)="openFileSharePopup(generateURLTemplate)">
                <i class="fa fa-link" aria-hidden="true"></i>
                Generate FS Script
            </button>
          </div>

        </div>

    </div>

</div>

<ng-template #uploadTemplate>

    <div class="modal-header text-center">
        <h4 class="modal-title w-100">Select file(s)</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-chunk-file-upload
            [HomeParams]="
                {
                    containerName : selectedContainer,
                    currentPath : selectedSubFolder ? selectedSubFolder : selectedTopFolder,
                    newFolderName : newFolderName
                }"
            (fileUploadEmitter)="fileUploadRefresh()">
        </app-chunk-file-upload>
    </div>

</ng-template>

<ng-template #folderTemplate>
    <div class="modal-header text-center">
        <h4 class="modal-title w-100">Create folder</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="createFolderModalRef?.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-create-folder (folderEventEmitter)="createFolderResult($event)"></app-create-folder>
    </div>
</ng-template>

<ng-template #notifyOwnerTemplate>
    <div class="modal-header text-center">
        <h4 class="modal-title w-100">Notification confirmation</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="notifyOwnerModalRef?.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="d-flex flex-row flex-wrap">
            <div *ngIf="!NotificationMessage" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                Do you want to notify this below path to the BU owner?
            </div>

            <div *ngIf="NotificationMessage" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div class="text-success" *ngIf="successNotifyMessage">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    {{successNotifyMessage}}
                </div>
                <div class="text-danger" *ngIf="errorNotifyMessage">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                    {{errorNotifyMessage}}
                </div>
            </div>

            <div *ngIf="!NotificationMessage" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <small class="text-muted">
                    Path : {{'/'+selectedContainer+'/'+ (selectedSubFolder ? selectedSubFolder : selectedTopFolder) + newFolderName}}
                </small>
            </div>
        </div>
    </div>

    <div *ngIf="!NotificationMessage" class="modal-footer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-md-right text-center">
            <button type="button" class="btn btn-sm btn-danger"(click)="notifyOwnerModalRef?.hide()"> <i class="fa fa-times-circle" aria-hidden="true"></i> No</button>
            <button type="button" class="btn btn-sm btn-success active ml-3" (click)="submitNotifyOwner()"><i class="fa fa-check-circle" aria-hidden="true"></i> Yes</button>
        </div>
    </div>

</ng-template>

<ng-template #uploadFolderTemplate>

    <div class="modal-header text-center">
        <h4 class="modal-title w-100">Select folder</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="uploadFolderModalRef?.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-chunk-folder-upload
            [HomeParams]="
                {
                    containerName : selectedContainer,
                    currentPath : selectedSubFolder ? selectedSubFolder : selectedTopFolder,
                    newFolderName : newFolderName
                }"
            (folderUploadEmitter)="folderUploadRefresh()">
        </app-chunk-folder-upload>
    </div>

</ng-template>

<ng-template #generateURLTemplate>
  <div class="modal-header text-center">
      <h4 class="modal-title w-100">Generate Script</h4>
      <button type="button" class="close pull-right" aria-label="Close"
          (click)="generateScriptModalRef?.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body">
    <app-fileshare-script-generate (scriptGenerateEmitter)="generateScript($event)"></app-fileshare-script-generate>
  </div>
</ng-template>

<ng-template #deleteContentTemplate>

    <app-delete-contents [contentParams]="
    {
        data : selectedContent,
        modalRef: deleteContentModalRef
    }"
    (deleteContentsEmitter)="contentDeleteRefresh($event)">
  </app-delete-contents>
</ng-template>
