import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-contents',
  templateUrl: './delete-contents.component.html',
  styleUrls: ['./delete-contents.component.scss']
})
export class DeleteContentsComponent {

  data:any;
  modalRef: BsModalRef;
  message = '';

  @Output() deleteContentsEmitter = new EventEmitter<any>();

  @Input() set contentParams(input:any) {
    this.data = input.data;
    this.modalRef = input.modalRef;
  }

  ngOnInit() {
    if(!this.data.isBlob) {
      const nameSplit = this.data.prefix.split('/')
      const name = nameSplit[nameSplit.length - 2]
      this.message = `Do you want to delete this folder - ${name}?`
    }
    else {
      const nameSplit = this.data.blob.name.split('/')
      const name = nameSplit[nameSplit.length - 1]
      this.message = `Do you want to delete this file - ${name}?`
    }
  }

  confirm() {
    this.deleteContentsEmitter.emit(this.data);
    this.modalRef.hide();
  }

}
