//Modules
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalAngularModule } from '@cvx/cal-angular';

// Environment
import { environment } from 'src/environments/environment';

//Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CreateFolderComponent } from './components/create-folder/create-folder.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

// Pipes
import { FolderNameSeperatorPipe } from './pipe/folder-name-seperator.pipe';

// Services
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';

// Guards
import { AuthGuard } from './app.routing.guard';
import { ChunkFileUploadComponent } from './components/chunk-file-upload/chunk-file-upload.component';
import { ChunkFolderUploadComponent } from './components/chunk-folder-upload/chunk-folder-upload.component';
import { SelectBuComponent } from './components/select-bu/select-bu.component';
import { Router } from '@angular/router';
import { BUStorageService } from './services/buStorage.service';
import { AddUserComponent } from './components/add-user/add-user.component';
import { FileshareScriptGenerateComponent } from './components/fileshare-script-generate/fileshare-script-generate.component';
import { DeleteContentsComponent } from './components/delete-contents/delete-contents.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FolderNameSeperatorPipe,
    CreateFolderComponent,
    LoaderComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    ChunkFileUploadComponent,
    ChunkFolderUploadComponent,
    SelectBuComponent,
    AddUserComponent,
    FileshareScriptGenerateComponent,
    DeleteContentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    ModalModule.forRoot(),
    MatProgressSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    CalAngularModule.forRoot(
    {
      "autoSignIn": true,
      "popupForLogin": false,
      "cacheLocation": "localStorage",
      "instance": "https://login.microsoftonline.com/",
      "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      "clientId": environment.calConfig.clientId,
      "redirectUri": environment.calConfig.postLogoutRedirectUri,
      "oidcScopes": environment.calConfig.oidcScopes,
      "graphScopes": ["User.Read", "User.Read.All"]
    })
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService, BUStorageService, Router],
      useFactory: (authSvc,busSvc, router) => {
          return () => {
            return authSvc.checkAccount().then((isUserLoggedIn: boolean) => {
              if(isUserLoggedIn) {
                return authSvc.getCALAccessToken().then(() => {
                  return authSvc.loadUserInfo().then((userInfo) => {
                    if(userInfo) {
                      return authSvc.loadAPIUrl().then((buDetails) => {
                        if(buDetails.bU_Name != null) {
                          busSvc.updateBusinessUnitValue(buDetails.bU_Name)
                          environment.apiEndPoint = buDetails.bU_Url
                          router.navigate(['/home'])
                        }
                        else {
                          router.navigate(['/select-bu'])
                        }
                      })
                    }
                  })
                })
              }
            })
          };
      },
    multi: true
    },
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
