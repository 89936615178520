<div *ngIf="hasAccess == true" class="container">

    <div class="card main-card-wrapper">

        <div class="card-header" style="padding:2px;">
            <div class="d-flex flex-row-reverse flex-wrap">
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-lg-right text-center">
                    <a class="hallmark cursor_icon" (click)="back()" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png" width="30"></a>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center" style="font-size: 20px;top: 2px;">
                    Subsea Media upload tool
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-lg-left text-center">
                    <a (click)="back()" title="Home">
                        <i class="fa fa-home cursor_icon" aria-hidden="true" style="font-size: 2rem;color: #fff;"></i>
                    </a>
                </div>

            </div>
        </div>
        <div style="min-height: 450px;">
            <div class="d-flex flex-wrap userInfo">
                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 text-center text-lg-left">
                    <ng-container *ngIf="businessUnit">
                        <i class="cursor_icon fa fa-arrow-circle-left back-to-selection" (click)="back()" aria-hidden="true"></i>
                        Click to select BU
                    </ng-container>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center">
                    You are logged in as: {{userFullName}}
                </div>
                <div *ngIf="businessUnit" class="col-lg-3 col-md-12 col-sm-12 col-xs-12 text-center text-lg-right">
                  Selected BU : {{businessUnit}}
                </div>
               <!--Add user button-->
                <div *ngIf="isAdmin && !businessUnit" class="col-lg-3 col-md-12 col-sm-12 col-xs-12 text-center text-lg-right">
                  <a (click)="addUser()" title="Admin">
                    <i class="fa fa-user cursor_icon" aria-hidden="true" style="font-size: 1.5rem;color: #0b2d71;"></i>
                  </a>
                </div>
            </div>
            <app-loader></app-loader>
            <router-outlet></router-outlet>
        </div>

    </div>

</div>
<div *ngIf="hasAccess == false" class="container">

    <div class="card main-card-wrapper">

        <div class="card-header" style="padding:2px;">
            <div class="d-flex flex-wrap">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style="font-size: 20px;top: 2px;">
                    Blob storage - File upload
                </div>
            </div>
        </div>

        <div style="min-height: 450px;">

            <div *ngIf="userFullName" class="d-flex flex-wrap userInfo">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    You are logged in as: {{userFullName}}
                </div>
            </div>

            <app-loader></app-loader>
            <router-outlet></router-outlet>
        </div>

    </div>

</div>
