import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './app.routing.guard';
import { HomeComponent } from './components/home/home.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SelectBuComponent } from './components/select-bu/select-bu.component';
import { AddUserComponent } from './components/add-user/add-user.component';

const routes: Routes = [
  {
    path:"",
    redirectTo:"/select-bu",
    pathMatch:"full",
    canActivate : [AuthGuard]
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "select-bu",
    component: SelectBuComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "add-user",
    component: AddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "error",
    children: [
      { path: "not-found", component: NotFoundComponent },
      { path: "not-authorized", component: NotAuthorizedComponent }
    ],
  },
  { path: "**",
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
