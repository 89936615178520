import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { BUStorageService } from './buStorage.service';

@Injectable({
  providedIn: 'root'
})

export class BlobService {

  constructor(private http: HttpClient,
    public authService: AuthService,
    private buStorageService: BUStorageService) { }

  getApiEndPoint(BU:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BUName",BU);

    return this.http.get(`${environment.defaultapiEndPoint}/Home/BUUrl`, {params: queryParams});
  }

  getBUSelectionInfo(email:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId",email);

    return this.http.get(`${environment.defaultapiEndPoint}/Home/getBUselectiondata`, {params: queryParams});
  }

  saveBU(BU:string,email:string) : Observable<any> {
    return this.http.post(`${environment.defaultapiEndPoint}/Home/saveBUselection`,{bu: BU, emailID: email});
  }

  getBusinessUnits(email:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Email",email);

    return this.http.get(`${environment.defaultapiEndPoint}/Home/BUdata`, {params: queryParams});
  }

  getContainers() : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BUName",this.buStorageService.businessUnitValue);

    let apiName = '/Home/getallcontainers';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
      apiName = '/Home/getallfileshares';
    }

    return this.http.get(`${environment.apiEndPoint}${apiName}`, {params: queryParams});
  }

  getTopFolders(containerName:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("containername",containerName);
    queryParams = queryParams.append("BUName",this.buStorageService.businessUnitValue);

    let apiName = '/Home/gettopblob';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
      apiName = '/Home/gettopfileshareFolders';
    }

    return this.http.get(`${environment.apiEndPoint}${apiName}`,{params: queryParams});
  }

  getSubFolders(containerName: string, prefix : string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("containername",containerName);
    queryParams = queryParams.append("prefix",prefix);
    queryParams = queryParams.append("BUName",this.buStorageService.businessUnitValue);

    let apiName = '/Home/getsubblob';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
      apiName = '/Home/getsubFileShareFolders';
    }

    return this.http.get(`${environment.apiEndPoint}${apiName}`,{params: queryParams});
  }

  downloadFile(containerName:string, fileName:string, chunkIndex = 0) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("blobName",fileName);
    queryParams = queryParams.append("containername",containerName);
    queryParams = queryParams.append("chunkIndex",chunkIndex);
    queryParams = queryParams.append("BUName",this.buStorageService.businessUnitValue);

    let apiName = '/Home/downloadchunk';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
      apiName = '/Home/downloadchunkfs';
    }

    return this.http.get(`${environment.apiEndPoint}${apiName}`,{params: queryParams,responseType:'blob',reportProgress:true,observe: "events"});
  }

  getClaims() {
    return this.http.get(`${environment.defaultapiEndPoint}/api/CvxClaimsPrincipal/GetClaims`);
  }

  uploadNormal(data, formData) : Observable<any> {
    let apiName = '/Home/upload';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
        apiName = '/Home/uploadfs';
    }
    const req = new HttpRequest(
      'POST',
       environment.apiEndPoint + apiName + (formData ? '?' + formData : ''),
       data,
       {
          reportProgress: true //This is required for track upload process
       }
    );

    return this.http.request(req)
  }

  uploadChunk(params,fileFormData) : Observable<any> {
    let apiName = '/Home/uploadchunk';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
        apiName = '/Home/uploadchunkfs';
    }
    const req = new HttpRequest(
      'POST',
       environment.apiEndPoint + apiName + (params ? '?' + params : ''),
       fileFormData,
       {
          reportProgress: true //This is required for track upload process
       }
    );

    return this.http.request(req)
  }

  notifyOwner(params:any) {
    return this.http.post(environment.apiEndPoint + '/api/Email/NotifyOwner' + (params ? '?' + params : ''),'');
  }

  downloadRecentHistory(params:any) {
    return this.http.post(`${environment.apiEndPoint}/Home/downloadExcel` + (params ? '?' + params : ''),'', {responseType : 'blob' as 'json'});
  }

  saveFileDetails(UploadDataModel) {
    return this.http.post(environment.apiEndPoint + '/Home/saveUploadFileDetails', UploadDataModel);
  }

  checkBlobExists(UploadDataModel,params) {
    let apiName = '/Home/blobExists';
    if (["ABU","GOM"].includes(this.buStorageService.businessUnitValue)){
        apiName = '/Home/fileExists';
    }
    return this.http.post(environment.apiEndPoint + apiName + (params ? '?' + params : ''), UploadDataModel);
  }

  formatBytes(bytes,decimal=2) {
    if(bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimal < 0 ? 0 : decimal;
    const sizes = ['Bytes','KB', 'MB', 'GB', 'TB','PB','EB','ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k,i)).toFixed(dm)) + ' ' + sizes[i]
  }

  excelNameGenerator() {
    //Any changes here Will affect in home and chunk upload components
    let currentdate = new Date();
    let name = 'Upload_Details_' + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + '_' +
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds() + ".xlsx";
    return name;
  }

  textNameGenerator() {
    //Any changes here Will affect in home
    let currentdate = new Date();
    let name = 'Fileshare_' + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + '_' +
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds() + ".txt";
    return name;
  }

  generateFile(blob, fileName) {
    //Any changes here Will affect in home and chunk upload components
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    element.click();
  }

  getDateAndTime() {
    let currentdate = new Date();
    let format = currentdate.getFullYear() + '-'
          + (currentdate.getMonth()+1) + '-'
          + currentdate.getDate() + ' '
          + currentdate.getHours() + ':'
          + currentdate.getMinutes() + ':'
          + currentdate.getSeconds() + '.'
          + currentdate.getMilliseconds();
    return format;
  }

}

