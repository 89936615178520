import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BlobService } from 'src/app/services/blob.service';
import { BUStorageService } from 'src/app/services/buStorage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-select-bu',
  templateUrl: './select-bu.component.html',
  styleUrls: ['./select-bu.component.scss']
})
export class SelectBuComponent implements OnInit {

  selectedBU = ''
  businessUnits :any[] = []

  constructor(private router: Router,
    private blobService: BlobService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private buStorageService: BUStorageService) { }

  ngOnInit(): void {
    this.buStorageService.updateBusinessUnitValue(this.buStorageService.businessUnitValue, false);
    this.getBusinessUnits()
  }

  getBusinessUnits() {
    let email = this.authService.getFullName()
    this.loaderService.show()
    this.blobService.getBusinessUnits(email).subscribe(
      {
        next : (res) => {
          this.loaderService.hide()
          if(res) 
          {
            this.businessUnits = res[0].bu.split(',')
          }
        },
        error : (err) => {
          this.loaderService.hide()
          console.log(err)
        }
      }
    )
  }

  goToNext() {
    if(this.buStorageService.businessUnitValue == this.selectedBU) {
      this.buStorageService.updateBusinessUnitValue(this.selectedBU)
      this.router.navigate(['/home'])
    }
    else 
    {
      this.saveBU();
    }
  }

  saveBU() {
    this.loaderService.show()
    let email = this.authService.getFullName()
    this.blobService.saveBU(this.selectedBU,email).subscribe(({
      next : (res:any) => {
        this.loaderService.hide()
        if(res.statusCode == 200) {
          this.setApiEndPoint(this.selectedBU)
        }
      },
      error: (err:any) => {
        this.loaderService.hide()
        console.log(err)
      }
    }))
  }

  setApiEndPoint(bu:any) {
    this.loaderService.show()
    this.blobService.getApiEndPoint(bu).subscribe({
      next:(res:any) => {
        this.loaderService.hide()
        if(res && this.selectedBU == res.bU_Name) {
          environment.apiEndPoint = res.bU_Url;
          this.buStorageService.updateBusinessUnitValue(this.selectedBU);
          this.router.navigate(['/home'])
        }
      },
      error:(err:any) => {
        this.loaderService.hide()
        console.log(err)
      }
    })
  }

}
