import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent {

  folderForm : FormGroup;
  @Output() folderEventEmitter = new EventEmitter<any>();
  
  constructor(private formBuilder: FormBuilder) {
      this.folderForm = this.formBuilder.group({
        'name' : [null, Validators.required]
      })
   }

  submitForm() {
    this.folderEventEmitter.emit(this.folderForm.get('name').value);
  }

}
