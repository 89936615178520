import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDetails } from '../models/userDetails';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) { }

  addUser(userDetails: UserDetails) : Observable<any> {
    return this.http.post(`${environment.defaultapiEndPoint}/Home/AddUser`, userDetails);
  }

  getUser(email:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId",email);

    return this.http.get(`${environment.defaultapiEndPoint}/Home/getUser`, {params: queryParams});
  }

  deleteUser(email:string) : Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId",email);
    return this.http.get(`${environment.defaultapiEndPoint}/Home/deleteUser`, {params: queryParams});
  }

  getavailableBUs() : Observable<any> {
    return this.http.get("/assets/data/businessUnits.json");
  }
}
